<template>
  <Stack
    tag="article"
    align="center"
    justify="start"
    class="pb-md md:h-[600px] max-w-[500px] m-auto"
    direction="col"
    :breakpoints="{ justify: { md: 'center' } }"
    gap="md"
    data-test-id="plpNoResults"
    data-fs="plpNoResults"
  >
    <SvgIcon name="product-search" svg-class="h-[64px] w-[64px]" class="text-mkm-blue-light" :width="64" :height="64" />
    <Stack tag="div" direction="col" gap="xs">
      <Heading size="lg" class="text-center">Oops!</Heading>
      <Text size="md" weight="bold" class="text-center"
        >{{ bigCommerceCategory?.name }} are not available to purchase online from {{ selectedBranch?.name }}</Text
      >
      <Text class="text-center"
        >Don't worry though, give the branch a call on
        <NuxtLink
          :to="`tel:${selectedBranch?.contacts?.phone}`"
          class="text-mkm-blue-light font-semibold"
          :aria-label="`Call ${selectedBranch?.name}`"
          >{{ selectedBranch?.contacts?.phone }}</NuxtLink
        >
        to find out about availability.</Text
      >
    </Stack>
  </Stack>
</template>

<script setup lang="ts">
const { selectedBranch } = useBranches();
const { bigCommerceCategory } = useCategory();
</script>
